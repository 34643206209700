import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getStorage } from 'firebase/storage';
import { getFirestore, initializeFirestore, persistentLocalCache, memoryLocalCache, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyDfQeZkzK9D1uQuXkoIaD6mcixmJOLD2bQ",
//     authDomain: "limagrain-offline.firebaseapp.com",
//     databaseURL: "https://limagrain-offline-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "limagrain-offline",
//     storageBucket: "limagrain-offline.appspot.com",
//     messagingSenderId: "769886272313",
//     appId: "1:769886272313:web:74449da7c829ee60cbc6e8",
//     measurementId: "G-YS3VBZ3FL6"
// };
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Realtime Database and export it
const database = getDatabase(firebaseApp);

// const databaseFireStore = getFirestore(firebaseApp);
const databaseFireStore = initializeFirestore(firebaseApp, {
    // cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    localCache: persistentLocalCache({
        synchronizeTabs: true  // Enable multi-tab support
    }),
});

const storage = getStorage(firebaseApp);

export { firebaseApp, database, databaseFireStore, storage, ref, onValue };