<script setup>
import { onMounted, ref, watch } from "vue";
import { useContextStore } from "@/stores/context";
import { utils } from "@/utilities";

const contextStore = useContextStore();
const user = ref(contextStore.getUser);
const title = process.env.VUE_APP_APP_TITLE;

const changeNetwork = ref(contextStore.getIsOffline ? 'offline' : 'online');
watch(
  () => changeNetwork.value,
  (v) => {
    console.log("change network", v);
    contextStore.updateIsOffline(v == 'offline');
  }
)

onMounted(() => {
  initFlowbite();
})
</script>
<template>
  <div class="min-h-screen bg-gray-100">
    <header>
      <nav class="py-2 bg-white border-b border-gray-100">
        <div
          class="flex flex-wrap justify-center lg:justify-between items-center mx-auto"
        >
          <router-link to="/" class="flex items-center">
            <h1 class="ml-3 text-2xl font-bold" style="color: #c41632">
              {{ title }}
            </h1>
          </router-link>
          <div class="flex flex-col md:flex-row items-center gap-2 lg:order-2">
            <div class="font-semibold text-sm md:text-lg">
              คุณ {{ user?.name ?? "-" }}
            </div>
            <router-link
              class="hidden md:block text-gray-800 border-primary hover:bg-primary hover:text-white border-2 font-semibold rounded-lg text-sm px-4 py-1"
              to="/logout"
              >Logout</router-link
            >

            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            class="hidden justify-between items-center w-full lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul
              class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0"
            >
              <li class="border-b">
                <router-link
                  to="/"
                  class="block py-2 pr-4 pl-3 !text-primary rounded lg:bg-transparent lg:text-primary-700 lg:p-0"
                  aria-current="page"
                  >Today Schedule
                </router-link>
              </li>
              <li>
                <router-link
                  to="logout"
                  class="block py-2 pr-4 pl-3 text-black rounded lg:bg-transparent lg:text-primary-700 lg:p-0"
                  >Logout</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <!-- <main class="container my-24 px-6 mx-auto"> -->
    <div class="flex page-content">
      <div
        class="hidden lg:flex flex-col w-full lg:w-64 h-100 text-gray-700 bg-white flex-shrink-0 z-10 border-r border-gray-200"
      >
        <div class="p-4">
          <ul>
            <li>
              <div>
                <button
                  class="flex items-center w-full px-4 py-2 mt-1 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                >
                  <div class="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      ></path>
                    </svg>
                  </div>
                  <div class="inline-block">Schedules</div>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    class="rotate-180 inline w-4 h-4 ml-1 transition-transform duration-200 transform"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div style="">
                <div class="px-2 py-2 bg-white dark-mode:bg-gray-800">
                  <router-link
                    class="flex items-center px-4 py-2 mt-0 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    to="/"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 text-gray-400 mr-2 z-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                    <div class="">Today Schedule</div>
                  </router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <main
        class="flex-auto w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible"
      >
        <div class="w-full container relative mx-auto px-2">
          <slot />
        </div>
      </main>
    </div>
    <!-- </main> -->
    <footer class="p-4 bg-white flex">
      <div class="w-full lg:w-64 hidden lg:block"></div>
      <div class="w-full flex justify-center items-center gap-2">
        <select v-show="utils.isDev()" v-model="changeNetwork">
          <option value="online">Set Online</option>
          <option value="offline">Set Offline</option>
        </select>
        <div
          v-if="contextStore.isOffline"
          class="text-lg font-bold text-primary"
        >
          Offline Mode
        </div>
        <div v-else class="text-lg font-bold text-green-400">Online Mode</div>
        <span class="text-xs text-gray-400">Version {{ utils.getAppVersion() }}</span>
      </div>
    </footer>
  </div>
</template>
<style>
.page-content {
  min-height: calc(95vh - 4rem - 1px);
}
</style>
