<script setup>
import { ref, onMounted, reactive } from "vue";
import Label from "@/components/Label";
import { notify } from "@kyvg/vue3-notification";
import { useLoading } from "vue-loading-overlay";
import { Modal } from "flowbite";
import { api } from "@/services/api";

import ActivityFS from "@/firebases/ActivityFS";

import { utils } from "@/utilities.js";

import { useContextStore } from "@/stores/context";
import LogFS from "@/firebases/LogFS";
const contextStore = useContextStore();

const activity = ref(null);

const props = defineProps([
  "activityId",
  "disabled",
  "salesRouteLineId",
  "alreadyClockIn",
]);
const emits = defineEmits(["checked"]);

const form = reactive({
  work_location: "",
  name: "",
  latitude: "",
  longitude: "",

  remark: "",

  current_latitude: "",
  current_longitude: "",
  distance: 0,

  sale_schedule: props.activityId,
  sale_route_line_id: props.salesRouteLineId ?? 0,
});

let modal = ref(null);
const $loading = useLoading({});

onMounted(async () => {
  activity.value = await ActivityFS.getById(props.activityId);

  // set the modal menu element
  const $targetEl = document.getElementById(generateElement(props.activityId));

  // options with default values
  const options = {
    placement: "bottom-right",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    closable: false,
    onShow: async () => {
      const loader = $loading.show({});

      if (activity.value == null) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: "Not found !",
        });

        loader.hide();
        closeModal();
        return false;
      }

      if (props.alreadyClockIn === false) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: "Please clock in before!",
        });

        loader.hide();
        closeModal();
        return false;
      }

      // Call the function to get the current position
      utils.getCurrentPosition().then((location) => {
        if (location) {
          form.current_latitude = location.latitude;
          form.current_longitude = location.longitude;
          form.latitude = location.latitude;
          form.longitude = location.longitude;

          submit();
        } else {
          notify({
            group: "bottom-center-group",
            type: "error",
            title: "Can't get the current position!",
          });
        }

        loader.hide();
      });
    },
  };

  // instance options object
  const instanceOptions = {
    id: generateElement(props.activityId),
    override: true,
  };

  modal.value = new Modal($targetEl, options, instanceOptions);
});

const showModal = () => {
  modal.value.show();
};

const closeModal = () => {
  modal.value.hide();
};

const submit = async () => {
  const loader = $loading.show({});

  if (contextStore.getIsOffline) {
    const newData = {
      ...utils.sanitizeObject(activity.value),
      sales_checkin: { id: -1, ...form, hashing: utils.generateUUID() },
    };
    ActivityFS.update(props.activityId, newData);

    setTimeout(() => {
      closeModal();
      loader.hide();
      emits("checked", true);
      return;
    }, 1000);
  } else {
    try {
      const result = await api.checkInActivity(form);
      if (result.status) {
        setTimeout(() => {
          closeModal();
          loader.hide();
          emits("checked", true);
          return;
        }, 1000);
      } else {
        const error = result.data;
        for (const [key, value] of Object.entries(error)) {
          notify({
            group: "bottom-center-group",
            type: "error",
            title: "There was an error!",
            text: value + " [ " + key + " ]",
          });

          if (key == "check-in") {
            const newData = {
              ...utils.sanitizeObject(activity.value),
              sales_checkin: { id: -2 },
            };
            ActivityFS.update(props.activityId, newData);

            setTimeout(() => {
              closeModal();
              loader.hide();
              emits("checked", true);
              return;
            }, 1500);
          } else {
            loader.hide();
            console.error("There was an error!", error);
            return;
          }
        }
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "There was an error!",
        text: "contact administrator !",
      });

      LogFS.createLog(error);
      console.error("There was an error!", error);
      loader.hide();
    }
  }
};

const generateElement = (activityId) => {
  return "check-in-modal-" + activityId;
};

const onFocus = (e) => {
  const target = e.currentTarget;

  target.type = "text";
  target.setSelectionRange(0, target.value.length);
  target.type = "number";
};
</script>
<template>
  <!-- Modal toggle -->
  <button
    :data-modal-target="generateElement(activityId)"
    :data-modal-toggle="generateElement(activityId)"
    type="button"
    class="bg-gray-800 px-4 py-2 text-white rounded-lg disabled:opacity-25 disabled:cursor-not-allowed w-full block mx-auto whitespace-nowrap"
    :disabled="disabled"
    @click.prevent="showModal"
  >
    <span
      class="text-center mx-auto whitespace-nowrap"
      v-if="!activity?.sales_checkin"
      >Check In</span
    >
    <span class="text-center mx-auto whitespace-nowrap" v-else>Checked</span>
  </button>

  <div
    :id="generateElement(activityId)"
    tabindex="-2"
    aria-hidden="true"
    class="my-modal hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t"
        >
          <h3 class="text-xl font-semibold text-gray-900">Check In</h3>
          <button
            type="button"
            class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            :data-modal-hide="generateElement(activityId)"
            @click.prevent="closeModal()"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <form class="space-y-4" action="#" @submit.prevent="submit">
            <div class="hidden">
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Distance (Km)"
              />
              <input
                type="number"
                v-model="form.distance"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @focus="utils.onFocusDefaultVal($event)"
                maxlength="5"
                min="0"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>

            <div class="flex justify-end">
              <button
                type="submit"
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
              >
                Check In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
