import { defineStore } from 'pinia';

export const useHybridStore = defineStore({
    id: 'hybrid',
    state() {
        return {
            database: null,
            hybrids: [],
            activeHybrid: {},
        };
    },
    actions: {
        async init() {
            // await this.initDatabase();
            this.initHybrids();
        },
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database 2.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };

                // db.onupgradeneeded = (e) => {
                //     if (e.oldVersion === 1) {
                //         e.target.result.deleteObjectStore("hybrids");
                //     }

                //     e.target.result.createObjectStore("hybrids", { keyPath: "id" });
                // };
            });
        },
        async initHybrids() {
            await this.initDatabase();
            const hybrids = await new Promise((resolve, reject) => {
                this.database.transaction('hybrids')
                    .objectStore('hybrids')
                    .getAll()
                    .onsuccess = (e) => {
                        resolve(e.target.result);
                    };
            });

            this.updateHybrids(hybrids);
        },
        async getHybrid(dataId) {
            await this.initDatabase();
            let id = parseInt(dataId);
            if (!this.database) {
                throw new Error('Database not initialized');
            }

            const transaction = this.database.transaction('hybrids', 'readwrite');

            const hybridStore = transaction.objectStore('hybrids');
            const hybridRequest = hybridStore.get(dataId);

            hybridRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            hybridRequest.onsuccess = (e) => {
                const findHybrid = e.target.result;
                if (findHybrid) {
                    this.updateActiveHybrid(findHybrid);
                }
            }
        },
        async addNewHybrid(data) {
            await this.initDatabase();
            const transaction = this.database.transaction('hybrids', 'readwrite');

            const hybridStore = transaction.objectStore('hybrids');
            const hybridRequest = hybridStore.get(data.value);

            hybridRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            hybridRequest.onsuccess = (e) => {
                const findHybrid = e.target.result;
                if (findHybrid) {
                    findHybrid.updated = new Date().getTime();

                    const updateRequest = hybridStore.put(findHybrid);
    
                    updateRequest.onerror = () => {
                        console.error('Error storing the updated in the database.');
                    };
    
                    updateRequest.onsuccess = () => {
                        const hybrids = [...this.hybrids];
                        const hybridIndex = hybrids.findIndex(n => n.id === findHybrid.id);
                        hybrids[hybridIndex] = findHybrid;
    
                        this.updateHybrids(hybrids);
                    };
                } else {
                    const now = new Date();
                    const newData = {
                        content: data,
                        created: now.getTime(),
                        updated: now.getTime(),
                        id: data.value
                    };
        
                    const hybrids = [newData, ...this.hybrids];
                    this.updateHybrids(hybrids);
        
                    this.updateActiveHybrid(newData);
    
                    transaction.objectStore('hybrids').add(newData);
                }
            }
        },

        updateDatabase(database) {
            this.database = database
        },
        updateHybrids(hybrids) {
            this.hybrids = hybrids
        },
        updateActiveHybrid(hybrid) {
            this.activeHybrid = hybrid
        },
        getHybrids() {
            return this.hybrids;
        }
    },
    getters: {},
});
