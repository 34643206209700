<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { authApi } from "@/services/authApi";
import { notify } from "@kyvg/vue3-notification";

import { useActivityAsyncStore } from "@/stores/activityAsync";
import { useContextStore } from "@/stores/context";

import ActivityFS from "@/firebases/ActivityFS";
import ClockInOutFS from "@/firebases/ClockInOutFS";

import { databaseFireStore } from "@/firebase";
import { enableNetwork, disableNetwork } from "firebase/firestore";

const activityAsyncStore = useActivityAsyncStore();
const contextStore = useContextStore();
const isSyncing = ref(false);

import { useRoute, useRouter } from "vue-router";
const router = useRouter();

const updateOnlineStatus = async (e) => {
  const { type } = e;
  const isOnline = type === "online";
  if (isOnline) {
    setTimeout(async () => {
      contextStore.updateIsOffline(false);

      if (isSyncing.value === false) {
        isSyncing.value = true;
        // sync up a user's data with an external api
        await authApi.refreshUser();
      }
      isSyncing.value = false;
    }, 1000);
  } else {
    contextStore.updateIsOffline(true);
  }
};

// Trigger if internet back to online fetch all data
// and update them to firebase
// and update check in/out when offline to online server
watch(
  () => contextStore.getIsOffline,
  async (n, o) => {
    if (n === false) {
      // going to be online
      await enableNetwork(databaseFireStore); // enable network

      if (contextStore.getAccessToken != '') {
        // Fetch and Update all
        await ActivityFS.updateAllActivities();
        await ClockInOutFS.updateAllClockInOut();
      }
    } else {
      await disableNetwork(databaseFireStore); // disable network
    }
  },
  {
    immediate: true,
  }
);

// Debug activity key duplicate
watch(
  () => activityAsyncStore.getSyncAsync,
  (n) => {
    console.log("getSyncAsync", n);
  },
  {
    deep: true,
  }
);

// initialize components based on data attribute selectors
onMounted(() => {
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);

  setInterval(() => {
    const hasClassOverflow = document.querySelector('body').classList.contains('overflow-hidden');
    const hasModalExists = document.querySelectorAll('div[modal-backdrop]').length;
    if (hasClassOverflow === false && hasModalExists) {
      // router.go(0);
      // window.location.href = '/dashboard';
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "Please reload page !",
      });
    }
  }, 5000);
});

onBeforeUnmount(() => {
  window.removeEventListener("online", updateOnlineStatus);
  window.removeEventListener("offline", updateOnlineStatus);
});
</script>

<template>
  <router-view />
  <notifications group="top-center-group" position="top center" classes="text-center" width="50%" :speed="700" />
  <notifications group="bottom-center-group" position="bottom center" width="50%" :speed="700" />
  <notifications group="top-right-group" position="top right" classes="text-center" width="50%" :speed="700" />
  <notifications group="bottom-right-group" position="bottom right" classes="text-center" width="50%" :speed="700" />

  <div class="py-2 bg-gray-400" v-show="updateExists">
    An update is available
    <button type="button" class="px-1.5 text-red-500 bg-white rounded-lg" @click="refreshApp"> Update </button>
  </div>
</template>

<style>
#app {
  font-family: Nunito, Helvetica, Arial, sans-serif;
  /* font-family: Sarabun, Helvetica, Arial, sans-serif; */
  /* font-family: "Sarabun"; */
  /* font-weight: 400; */
  /* font-style: normal; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

div.notification-title {
  text-align: center;
}
</style>
