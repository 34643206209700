// export const dateWithoutTime = function (date) {
//     return date ? date.split("T")[0] : ""
// }
import { ref, onMounted, watch, computed, isProxy, toRaw } from "vue";
import { useContextStore } from "@/stores/context";
import axios from 'axios';

export const utils = {
    isDev() {
        return process.env.NODE_ENV === "development";
    },
    dateWithoutTimes: (date) => {
        return date ? date.split("T")[0] : ""
    },
    sanitizeObject: (obj) => {
        // Create a new object to store sanitized data
        const sanitized = Array.isArray(obj) ? [] : {};

        // Iterate through each property of the object
        for (const key in obj) {
            // Check if the property is an object (including arrays)
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // If the property is a proxy object, convert it to a plain object using `toRaw`
                if (isProxy(obj[key])) {
                    sanitized[key] = utils.sanitizeObject(toRaw(obj[key]));
                } else {
                    // Recursively sanitize nested objects
                    sanitized[key] = utils.sanitizeObject(obj[key]);
                }
            } else {
                // If the property is a primitive type, simply assign it to the sanitized object
                sanitized[key] = obj[key];
            }
        }
        return sanitized;
    },
    getDistanceFromActivity: (activity) => {
        if (activity?.sales_checkin?.geoLocation?.distance) {
            return activity.sales_checkin.geoLocation.distance;
        } else if (activity?.sales_checkin?.geo_location?.distance) {
            return activity.sales_checkin.geo_location.distance;
        } else if (activity?.sales_checkin?.distance) {
            return activity?.sales_checkin?.distance;
        }

        return 0;
    },
    activityStr: (type) => {
        let str =
            type == "App\\Models\\SAMeetWithDealerSubDealer"
                ? "DEALER/SUB MEETING"
                : type == "App\\Models\\SAFieldDay"
                    ? "FIELD DAY"
                    : type == "App\\Models\\SASpotSale"
                        ? "SPOT SALE"
                        : type == "App\\Models\\SAFocusVillage"
                            ? "CORN TOUR"
                            : type == "App\\Models\\SAFarmerMeeting"
                                ? "FARMER MEETING"
                                : type == "App\\Models\\SAContact"
                                    ? "CONTACT"
                                    : type == "App\\Models\\SACaravan"
                                        ? "CARAVAN"
                                        : type == "App\\Models\\SAHarvest"
                                            ? "HARVEST"
                                            : type == "App\\Models\\SAOtherCommercial"
                                                ? "OTHER"
                                                : "";
        return str;
    },
    getContentProxy: (proxyData) => {
        if (proxyData.hasOwnProperty("content")) {
            return utils.sanitizeObject(proxyData.content);
        }

        return proxyData;
    },
    convertToSelectObject: async (storeContext) => {
        return storeContext.map((d) => {
            return utils.getContentProxy(d);
        });
    },

    // Function to get the current position using async/await
    getCurrentPosition: async () => {
        const contextStore = useContextStore();
        if ("geolocation" in navigator) {
            // Geolocation is supported

            console.log("Trying to get current position.....");

            // Wrap the geolocation function call in a promise
            try {
                const position = await new Promise((resolve, reject) => {
                    if (contextStore.getIsOffline) {
                        // Set up a timeout
                        const timer = setTimeout(() => {
                            reject(new Error("Geolocation request timed out"));
                        }, 5000); // 5 seconds timeout

                        // Get the current position
                        navigator.geolocation.getCurrentPosition(
                            (pos) => {
                                clearTimeout(timer); // Clear the timeout on success
                                resolve(pos);
                            },
                            (err) => {
                                clearTimeout(timer); // Clear the timeout on error
                                reject(err);
                            }
                        );
                    } else {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    }
                });

                // Retrieve latitude and longitude from the position
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log("Latitude:", latitude, "Longitude:", longitude);

                // Return the position or the latitude and longitude as needed
                return { latitude, longitude };
            } catch (error) {
                // Handle the error as necessary
                console.error("Error getting location:", error.message);

                const latitude = -1;
                const longitude = -1;
                return { latitude, longitude };
            }
        } else {
            console.error("Geolocation is not supported by this browser.");

            const latitude = -1;
            const longitude = -1;
            return { latitude, longitude };
        }
    },
    formatDateTimeByUnix: (unix) => {
        return new Date(unix).toLocaleTimeString('th-TH', { hour12: false, hour: "numeric", minute: "numeric" });
    },
    isSameDay(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        // Check if year, month, and day of date1 are equal to those of date2
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    },
    isDayPass(date) {
        const givenDate = new Date(date);

        // Get the current date and time
        const currentDate = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - givenDate;

        // Convert the time difference from milliseconds to days
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

        // console.log(givenDate, currentDate, timeDifference, daysDifference, daysDifference > 1);

        // Check if the difference is more than x day
        return daysDifference > 1;
    },
    isMoreThanDays(date1, date2) {
        // Calculate the difference in milliseconds between the two dates
        const diffInMs = Math.abs(date2 - date1);
        // Convert milliseconds to days
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        // Check if the difference in days is greater than 15
        return diffInDays > 15;
    },
    isActivityHasChanged(activity) {
        if (activity?.id) {
            if (isNaN(activity.id)) {
                return true;
            }

            if (activity?.sales_checkin && activity?.sales_checkin?.id && activity?.sales_checkin?.id == -1) {
                return true;
            }

            if (activity?.sales_checkout && activity?.sales_checkout?.id && activity?.sales_checkout?.id == -1) {
                return true;
            }
        }

        return false;
    },
    hasRequiredKeys(obj, requiredKeys) {
        // Iterate over each required key
        for (let key of requiredKeys) {
            // Check if the key is present in the object
            if (!obj.hasOwnProperty(key)) {
                // If any key is missing, return false
                return false;
            }
        }
        // If all keys are present, return true
        return true;
    },
    convertToFormData(data) {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                data[key].forEach((item, index) => {
                    if (item instanceof File) {
                        // formData.append(`${key}[${index}]`, item);
                        formData.append(`${key}[]`, item);
                    } else {
                        formData.append(`${key}[${index}]`, JSON.stringify(item));
                    }
                });
            } else if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        });

        return formData;
    },
    onFocusDefaultVal(e) {
        const target = e.currentTarget;

        target.type = "text";
        target.setSelectionRange(0, target.value.length);
        target.type = "number";

        return;
    },
    generateUUID() {
        const res = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            v.toString(16);
            return v.toString(16)
        });

        return res;
    },
    jsonFriendlyErrorReplacer(value) {
        var errorObject = value;

        if (axios.isAxiosError(errorObject)) {
            const { message, name, stack, config, code, request, response } = errorObject;

            // Convert headers if they are functions
            const serializeHeaders = (headers) => {
                if (headers && typeof headers === 'object') {
                    return Object.keys(headers).reduce((result, key) => {
                        result[key] = typeof headers[key] === 'function' ? headers[key].toString() : headers[key];
                        return result;
                    }, {});
                }

                return headers;
            };

            const formDataToJSON = (formData) => {
                if (!(formData instanceof FormData)) return formData;
                const json = {};
                formData.forEach((_value, key) => {
                    json[key] = _value;
                });

                return json;
            }

            // Extract additional information from the response if available
            const errorDetails = response ? {
                status: response.status,
                statusText: response.statusText,
                headers: serializeHeaders(response.headers),
                data: response.data,
            } : {};

            // Extract request details from config
            const requestDetails = config ? {
                url: config.url,
                method: config.method,
                headers: serializeHeaders(config.headers),
                data: config.data instanceof FormData ? formDataToJSON(config.data) : config.data,
                timeout: config.timeout,
            } : undefined;

            // Combine all extracted information into a single object
            errorObject = {
                message,
                name,
                stack,
                code,
                request: requestDetails,
                response: errorDetails,
            };
        } else if (value instanceof Error) {
            errorObject = {
                // Pull all enumerable properties, supporting properties on custom Errors
                ...value,
                // Explicitly pull Error's non-enumerable properties
                name: value.name,
                message: value.message,
                stack: value.stack,
            }
        }

        const replaceUndefinedWithNull = (obj) => {
            if (obj === null || typeof obj !== 'object') {
                return obj;
            }

            for (let key in obj) {
                if (obj[key] === undefined) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'function') {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    replaceUndefinedWithNull(obj[key]);
                }
            }

            return obj;
        }

        // return JSON.parse(JSON.stringify(value));
        return replaceUndefinedWithNull(errorObject);
    },
    sortArrayActivity(arr) {
        arr.sort((a, b) => {
            if (a.id !== undefined && b.id !== undefined && !isNaN(a.id) && !isNaN(b.id)) {
                return a.id - b.id;
            } else if (a.id !== undefined && !isNaN(a.id)) {
                return -1; // a comes first
            } else if (b.id !== undefined && !isNaN(b.id)) {
                return 1; // b comes first
            } else {
                return a.order_sorted - b.order_sorted;
            }
        });

        return arr;
    },
    getCurrentDate() {
        return new Date().toISOString().split("T")[0];
    },
    getCurrentDateTime() {
        return new Date().toISOString().split("T")[0] + " 00:00:00";
    },
    getCurrentTime() {
        return new Date().toLocaleTimeString('th-TH', { hour12: false, hour: "numeric", minute: "numeric" });
    },
    getAppVersion() {
        return process.env.VUE_APP_VERSION || 0;
    }
}