import { defineStore } from 'pinia';

export const useContextStore = defineStore({
    id: 'context-store',
    state() {
        return {
            auth: null,
            user: null,
            isOffline: !navigator.onLine,
        };
    },
    actions: {
        async init() {
            this.auth = $cookies.get("auth") ?? null;
            this.user = $cookies.get("auth")?.user ?? null;

            // If is expired
            if (this.auth && new Date() > new Date(this.auth.expired_at)) {
                window.location.href = '/logout';
            }
        },

        updateIsOffline(isOffline) {
            this.isOffline = isOffline
        },
        updateAuth(data) {
            this.auth = data;
        },
        updateUser(data) {
            this.user = data;
        },
    },
    getters: {
        getIsOffline(state) {
            return state.isOffline;
        },
        getAuth(state) {
            return state.auth;
        },
        getUser(state) {
            return state.user;
        },
        getAccessToken(state) {
            return state.auth?.access_token ?? '';
        }
    },
});
