<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppLayout from "@/layouts/AppLayout.vue";
import Label from "@/components/Label";
import { useLoading } from "vue-loading-overlay";
import { notify } from "@kyvg/vue3-notification";
import { api } from "@/services/api";
import { utils } from "@/utilities";

import { WorkLocationApi } from "@/services/workLocationApi";

import ClockInOutFS from "@/firebases/ClockInOutFS";

import { useContextStore } from "@/stores/context";
const contextStore = useContextStore();

import { useWorkLocationStore } from "@/stores/workLocation";
const workLocationStore = useWorkLocationStore();

const loadCompleted = ref(false);

let workLocationOptions = ref([]);
const activeWorkLocation = ref(null);

const router = useRouter();

const $loading = useLoading({});

const form = reactive({
  work_location: "",
  name: "",
  latitude: "",
  longitude: "",

  current_latitude: "",
  current_longitude: "",
  remark: "",

  type: "clock-in",

  distance: 0,
  miles: 0,

  newLocation: {},
  needUpdateClockIn: false,

  hashing: utils.generateUUID(),
});

onMounted(async () => {
  await fetchWorkLocations();
  await workLocationStore.initWorkLocations();

  loadCompleted.value = true;
  workLocationOptions.value = workLocationStore.getWorkLocations();
  workLocationOptions.value = await utils.convertToSelectObject(
    workLocationOptions.value
  );

  // Call the function to get the current position
  utils.getCurrentPosition().then((location) => {
    if (location) {
      form.current_latitude = location.latitude;
      form.current_longitude = location.longitude;
      form.latitude = location.latitude;
      form.longitude = location.longitude;
    } else {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "Can't get the current position!",
      });
    }
  });
});

const fetchWorkLocations = async () => {
  if (contextStore.getIsOffline === false) {
    const response = await WorkLocationApi.getWorkLocations();
    for (const res of response) {
      workLocationStore.addNewWorkLocation(res);
    }
  }
};

watch(
  () => loadCompleted.value,
  (n, o) => {
    const loader = $loading.show({});

    setTimeout(() => {
      loader.hide();
    }, 1000);
  }
);

watch(
  () => form.work_location,
  async (v) => {
    form.latitude = "";
    form.longitude = "";

    if (isNaN(v)) {
      const loader = $loading.show({});

      // Call the function to get the current position
      utils.getCurrentPosition().then((location) => {
        if (location) {
          form.current_latitude = location.latitude;
          form.current_longitude = location.longitude;
          form.latitude = location.latitude;
          form.longitude = location.longitude;
        } else {
          notify({
            group: "bottom-center-group",
            type: "error",
            title: "Can't get the current position!",
          });
          return;
        }

        loader.hide();
      });
    } else {
      activeWorkLocation.value = null;
      const result = await workLocationStore.getWorkLocation(v);
      if (result) {
        activeWorkLocation.value = utils.getContentProxy(result);

        form.latitude = activeWorkLocation.value.latitude;
        form.longitude = activeWorkLocation.value.longitude;
      }
    }
  }
);

const submit = async () => {
  if (form.work_location == "use-current") {
    if (form.name == "") {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "Address name is required !",
      });
      return;
    }

    form.newLocation = {
      location: "other",
      name: form.name,
      latitude: form.latitude,
      longitude: form.longitude,
    };
  }

  if (contextStore.getIsOffline) {
    form.needUpdateClockIn = true;
    ClockInOutFS.addClockInToday(form);
    
    router.push("/");
  } else {
    const clockInOut = await api.clockInOut({ formClockIn: form });
    if (clockInOut.status) {
      ClockInOutFS.addClockInToday(clockInOut.data['clockIn']);

      router.push("/");
    } else {
      const error = clockInOut.data;
      for (const [key, value] of Object.entries(error)) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: "There was an error!",
          text: value + " [ " + key + " ]",
        });

        if (key == "clock-in") {
          ClockInOutFS.addClockInToday({});

          router.push("/");
        }

        return;
      }

      console.error("There was an error!", error);
      return;
    }
  }
};
</script>

<template>
  <AppLayout>
    <div class="relative overflow-x-auto my-6">
      <div class="text-lg font-bold my-3 text-left">Clock In</div>
      <form
        class="space-y-4 text-left"
        action="#"
        @submit.prevent="submit"
        v-if="loadCompleted"
      >
        <div>
          <Label
            class="block mb-2 text-sm font-medium text-gray-900 required"
            value="Work location:"
          />
          <select
            class="w-full rounded-lg"
            v-model="form.work_location"
            required
          >
            <option value="" disabled selected>
              ----- Choose work location -----
            </option>
            <option
              :value="location.id"
              v-for="(location, index) in workLocationOptions"
              :key="location.id"
            >
              {{ location.name ?? "Other" }}
            </option>
            <option value="use-current">Use current location</option>
          </select>
        </div>
        <div class="py-2" v-if="form.work_location == 'use-current'">
          <Label
            class="block mb-2 text-sm font-medium text-gray-900 required"
            value="Address name"
          />
          <input
            class="mt-0 block w-full rounded-lg"
            type="text"
            v-model="form.name"
            required
          />
        </div>

        <div class="py-2 hidden">
          <Label
            class="block mb-2 text-sm font-medium text-gray-900 required"
            value="Distance (Km)"
          />
          <input
            type="number"
            v-model="form.distance"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            @focus="utils.onFocusDefaultVal($event)"
            maxlength="5"
            min="0"
            class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>

        <div class="py-2">
          <Label
            class="block mb-2 text-sm font-medium text-gray-900 required"
            value="Miles"
          />
          <input
            type="text"
            v-model="form.miles"
            @focus="utils.onFocusDefaultVal($event)"
            class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
          />
        </div>

        <div class="py-2">
          <Label
            class="block mb-2 text-sm font-medium text-gray-900"
            value="Remark"
          />
          <textarea
            class="mt-0 block w-full rounded-lg resize-y"
            rows="3"
            v-model="form.remark"
          />
        </div>

        <div class="flex justify-end">
          <button
            type="submit"
            class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
          >
            Clock In
          </button>
        </div>
      </form>
    </div>
  </AppLayout>
</template>
