import { defineStore } from 'pinia';

export const useDealerStore = defineStore({
    id: 'dealer',
    state() {
        return {
            database: null,
            dealers: [],
            activeDealer: {},
        };
    },
    actions: {
        async init() {
            // await this.initDatabase();
            this.initDealers();
        },
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database 2.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };

                // db.onupgradeneeded = (e) => {
                //     if (e.oldVersion === 1) {
                //         e.target.result.deleteObjectStore("dealers");
                //     }

                //     e.target.result.createObjectStore("dealers", { keyPath: "id" });
                // };
            });
        },
        async initDealers() {
            await this.initDatabase();
            const dealers = await new Promise((resolve, reject) => {
                this.database.transaction('dealers')
                    .objectStore('dealers')
                    .getAll()
                    .onsuccess = (e) => {
                        resolve(e.target.result);
                    };
            });

            this.updateDealers(dealers);
        },
        async getDealer(dataId) {
            await this.initDatabase();
            let id = parseInt(dataId);
            if (!this.database) {
                throw new Error('Database not initialized');
            }

            const transaction = this.database.transaction('dealers', 'readwrite');

            const dealerStore = transaction.objectStore('dealers');
            const dealerRequest = dealerStore.get(dataId);

            dealerRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            dealerRequest.onsuccess = (e) => {
                const findDealer = e.target.result;
                if (findDealer) {
                    this.updateActiveDealer(findDealer);
                }
            }
        },
        async addNewDealer(data) {
            await this.initDatabase();
            const transaction = this.database.transaction('dealers', 'readwrite');

            const dealerStore = transaction.objectStore('dealers');
            const dealerRequest = dealerStore.get(data.value);

            dealerRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            dealerRequest.onsuccess = (e) => {
                const findDealer = e.target.result;
                if (findDealer) {
                    findDealer.updated = new Date().getTime();

                    const updateRequest = dealerStore.put(findDealer);
    
                    updateRequest.onerror = () => {
                        console.error('Error storing the updated in the database.');
                    };
    
                    updateRequest.onsuccess = () => {
                        const dealers = [...this.dealers];
                        const dealerIndex = dealers.findIndex(n => n.id === findDealer.id);
                        dealers[dealerIndex] = findDealer;
    
                        this.updateDealers(dealers);
                    };
                } else {
                    const now = new Date();
                    const newData = {
                        content: data,
                        created: now.getTime(),
                        updated: now.getTime(),
                        id: data.value
                    };
        
                    const dealers = [newData, ...this.dealers];
                    this.updateDealers(dealers);
        
                    this.updateActiveDealer(newData);
    
                    transaction.objectStore('dealers').add(newData);
                }
            }
        },

        updateDatabase(database) {
            this.database = database
        },
        updateDealers(dealers) {
            this.dealers = dealers
        },
        updateActiveDealer(dealer) {
            this.activeDealer = dealer
        },
        getDealers() {
            return this.dealers;
        }
    },
    getters: {},
});
