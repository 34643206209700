<script setup>
import AppLayout from "@/layouts/AppLayout.vue";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();

onMounted(() => {
  router.push('/')
});
</script>

<template>
  <AppLayout>
    <div>Dashboard</div>
  </AppLayout>
</template>
