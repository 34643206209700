import { defineStore } from 'pinia';

export const useFarmerStore = defineStore({
    id: 'farmer',
    state() {
        return {
            database: null,
            farmers: [],
            activeFarmer: {},
        };
    },
    actions: {
        async init() {
            // await this.initDatabase();
            this.initFarmers();
        },
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database 2.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };

                // db.onupgradeneeded = (e) => {
                //     if (e.oldVersion === 1) {
                //         e.target.result.deleteObjectStore("farmers");
                //     }

                //     e.target.result.createObjectStore("farmers", { keyPath: "id" });
                // };
            });
        },
        async initFarmers() {
            await this.initDatabase();
            const farmers = await new Promise((resolve, reject) => {
                this.database.transaction('farmers')
                    .objectStore('farmers')
                    .getAll()
                    .onsuccess = (e) => {
                        resolve(e.target.result);
                    };
            });

            this.updateFarmers(farmers);
        },
        async getFarmer(dataId) {
            await this.initDatabase();
            if (!this.database) {
                throw new Error('Database not initialized');
            }

            const transaction = this.database.transaction('farmers', 'readwrite');

            const farmerStore = transaction.objectStore('farmers');
            const farmerRequest = farmerStore.get(dataId);

            farmerRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            farmerRequest.onsuccess = (e) => {
                const findFarmer = e.target.result;
                if (findFarmer) {
                    this.updateActiveFarmer(findFarmer);
                }
            }
        },
        async addNewFarmer(data) {
            await this.initDatabase();
            const transaction = this.database.transaction('farmers', 'readwrite');

            const farmerStore = transaction.objectStore('farmers');
            const farmerRequest = farmerStore.get(data.value);

            farmerRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            farmerRequest.onsuccess = (e) => {
                const findFarmer = e.target.result;
                if (findFarmer) {
                    findFarmer.updated = new Date().getTime();

                    const updateRequest = farmerStore.put(findFarmer);
    
                    updateRequest.onerror = () => {
                        console.error('Error storing the updated in the database.');
                    };
    
                    updateRequest.onsuccess = () => {
                        const farmers = [...this.farmers];
                        const farmerIndex = farmers.findIndex(n => n.id === findFarmer.id);
                        farmers[farmerIndex] = findFarmer;
    
                        this.updateFarmers(farmers);
                    };
                } else {
                    const now = new Date();
                    const newData = {
                        content: data,
                        created: now.getTime(),
                        updated: now.getTime(),
                        id: data.value
                    };
        
                    const farmers = [newData, ...this.farmers];
                    this.updateFarmers(farmers);
        
                    this.updateActiveFarmer(newData);
    
                    transaction.objectStore('farmers').add(newData);
                }
            }
        },

        updateDatabase(database) {
            this.database = database
        },
        updateFarmers(farmers) {
            this.farmers = farmers
        },
        updateActiveFarmer(farmer) {
            this.activeFarmer = farmer
        },
        getFarmers() {
            return this.farmers;
        }
    },
    getters: {},
});
