import { defineStore } from 'pinia';
import { useContextStore } from "@/stores/context";

export const useWorkLocationStore = defineStore({
    id: 'workLocation',
    state() {
        return {
            database: null,
            workLocations: [],
            activeWorkLocation: {},
        };
    },
    actions: {
        async init() {
            // await this.initDatabase();
            this.initWorkLocations();
        },
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database 2.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };
            });
        },
        async initWorkLocations() {
            await this.initDatabase();
            const workLocations = await new Promise((resolve, reject) => {
                this.database.transaction('workLocations')
                    .objectStore('workLocations')
                    .getAll()
                    .onsuccess = (e) => {
                        resolve(e.target.result);
                    };
            });

            const contextStore = useContextStore();
            let filters = [];

            if (contextStore.getUser) {
                filters = workLocations.filter((val) => parseInt(val.content.sales_representative_id) == parseInt(contextStore.getUser.id));
            }
            
            this.updateWorkLocations(filters);
        },
        async getWorkLocation(dataId) {
            await this.initDatabase();
            let id = parseInt(dataId);
            if (!this.database) {
                throw new Error('Database not initialized');
            }

            return new Promise((resolve, reject) => {
                const transaction = this.database.transaction('workLocations', 'readwrite');
        
                const workLocationStore = transaction.objectStore('workLocations');
                const workLocationRequest = workLocationStore.get(id);
        
                workLocationRequest.onerror = () => {
                    console.error('Error finding in the database.');
                    reject('Error finding in the database.');
                };
        
                workLocationRequest.onsuccess = (e) => {
                    const findWorkLocation = e.target.result;
                    if (findWorkLocation) {
                        this.updateActiveWorkLocation(findWorkLocation);
                        resolve(findWorkLocation);
                    } else {
                        resolve(null); // Resolve with null if work location is not found
                    }
                }
            });
        },
        async addNewWorkLocation(data) {
            await this.initDatabase();
            const transaction = this.database.transaction('workLocations', 'readwrite');

            const workLocationStore = transaction.objectStore('workLocations');
            const workLocationRequest = workLocationStore.get(data.id);

            workLocationRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            workLocationRequest.onsuccess = (e) => {
                const findWorkLocation = e.target.result;
                if (findWorkLocation) {
                    findWorkLocation.updated = new Date().getTime();

                    const updateRequest = workLocationStore.put(findWorkLocation);
    
                    updateRequest.onerror = () => {
                        console.error('Error storing the updated in the database.');
                    };
    
                    updateRequest.onsuccess = () => {
                        const workLocations = [...this.workLocations];
                        var workLocationIndex;
                        if (workLocations.length > 0) {
                            workLocationIndex = workLocations.findIndex(n => n.id === findWorkLocation.id);
                        } else {
                            workLocationIndex = 0;
                        }
                        workLocations[workLocationIndex] = findWorkLocation;
    
                        this.updateWorkLocations(workLocations);
                    };
                } else {
                    const now = new Date();
                    const newData = {
                        content: {...data},
                        created: now.getTime(),
                        updated: now.getTime(),
                        id: data.id
                    };
        
                    const workLocations = [newData, ...this.workLocations];
                    this.updateWorkLocations(workLocations);
                    this.updateActiveWorkLocation(newData);
    
                    transaction.objectStore('workLocations').add(newData);
                }
            }
        },
        updateDatabase(database) {
            this.database = database
        },
        updateWorkLocations(workLocations) {
            this.workLocations = workLocations
        },
        updateActiveWorkLocation(workLocation) {
            this.activeWorkLocation = workLocation
        },
        getWorkLocations() {
            return this.workLocations;
        }
    },
    getters: {},
});
