<script setup>
import { ref, reactive, onMounted } from "vue";
import AppLayout from "@/layouts/AppLayout.vue";
import { useRoute, useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import { useLoading } from "vue-loading-overlay";
import { utils } from "@/utilities";
import { api } from "@/services/api";

import MeetDealerSubDealer from "@/views/Activity/Form/MeetDealerSubDealer.vue";
import SpotSale from "@/views/Activity/Form/SpotSale.vue";
import Caravan from "@/views/Activity/Form/Caravan.vue";
import FarmerMeeting from "@/views/Activity/Form/FarmerMeeting.vue";
import Contact from "@/views/Activity/Form/Contact.vue";
import FieldDay from "@/views/Activity/Form/FieldDay.vue";
import FocusVillage from "@/views/Activity/Form/FocusVillage.vue";
import Harvest from "@/views/Activity/Form/Harvest.vue";
import Other from "@/views/Activity/Form/Other.vue";

import ActivityFS from "@/firebases/ActivityFS";

import { useFileStore } from "@/stores/file";

import { useContextStore } from "@/stores/context";
import LogFS from "@/firebases/LogFS";
const contextStore = useContextStore();

const fileStore = useFileStore();

const router = useRouter();
const route = useRoute();

const $loading = useLoading({});

const activityId = ref(route.params.activity_id);
const activity = ref(null);

const myLocation = reactive({
  ok: false,
  current_latitude: null,
  current_longitude: null,
  latitude: null,
  longitude: null,
});

onMounted(async () => {
  const loader = $loading.show({});

  activity.value = await ActivityFS.getById(activityId.value);
  if (activity.value == null) {
    notify({
      group: "bottom-center-group",
      type: "error",
      title: "Not found !",
    });

    router.push("/");
  }

  if (activity.value?.hasOwnProperty("sales_checkin") === false) {
    notify({
      group: "bottom-center-group",
      type: "error",
      title: "Please check in before!",
    });

    router.push("/");
  }

  if (activity.value?.hasOwnProperty("sales_checkout") === true && activity.value.sales_checkout) {
    notify({
      group: "bottom-center-group",
      type: "error",
      title: "Already check out!",
    });

    router.push("/");
  }

  // for (let file of activity.value?.tempFiles) {
  //   console.log(await fileStore.getFile(file));
  // }

  // Call the function to get the current position
  utils.getCurrentPosition().then((location) => {
    if (location) {
      myLocation.ok = true;
      myLocation.current_latitude = location.latitude;
      myLocation.current_longitude = location.longitude;
      myLocation.latitude = location.latitude;
      myLocation.longitude = location.longitude;
    } else {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "Can't get the current position!",
      });
    }

    loader.hide();
  });
});

const submitMaster = async (form) => {
  const loader = $loading.show({});

  if (contextStore.getIsOffline) {
    var tempFiles = [];
    if (form.files) {
      for (const file of form.files) {
        let name = utils.generateUUID();
        fileStore.saveFile(file, name);
        tempFiles.push(name);
      }
    }

    const newData = {
      ...utils.sanitizeObject(activity.value),
      sales_checkout: { id: -1, ...form, files: [], hashing: utils.generateUUID() },
      tempFiles: tempFiles,
    };
    ActivityFS.update(activity.value.id, newData);

    setTimeout(() => {
      loader.hide();
      router.push("/");
      return;
    }, 1000);
  } else {
    try {
      const result = await api.checkOutActivity(form);
      if (result.status) {
        setTimeout(() => {
          loader.hide();
          router.push("/");
          return;
        }, 1000);
      } else {
        const error = result.data;
        for (const [key, value] of Object.entries(error)) {
          notify({
            group: "bottom-center-group",
            type: "error",
            title: "There was an error!",
            text: value + " [ " + key + " ]",
          });

          if (key == "check-out") {
            const newData = {
              ...utils.sanitizeObject(activity.value),
              sales_checkout: { id: -2 },
            };
            ActivityFS.update(activity.value.id, newData);

            setTimeout(() => {
              loader.hide();
              router.push("/");
              return;
            }, 1500);
          } else {
            loader.hide();
            console.error("There was an error!", error);
            return;
          }
        }
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "There was an error!",
        text: "contact administrator !",
      });

      LogFS.createLog(error);
      console.error("There was an error!", error);
      loader.hide();
    }
  }
};
</script>

<template>
  <AppLayout>
    <div class="relative overflow-x-auto my-6">
      <div class="text-lg font-bold m-3 text-left">Check Out</div>

      <div class="space-y-4 px-4" v-if="myLocation.ok && activity">
        <MeetDealerSubDealer
          v-if="
            activity.activity_type == 'App\\Models\\SAMeetWithDealerSubDealer'
          "
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <SpotSale
          v-if="activity.activity_type == 'App\\Models\\SASpotSale'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <Caravan
          v-if="activity.activity_type == 'App\\Models\\SACaravan'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <FarmerMeeting
          v-if="activity.activity_type == 'App\\Models\\SAFarmerMeeting'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <Contact
          v-if="activity.activity_type == 'App\\Models\\SAContact'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <FieldDay
          v-if="activity.activity_type == 'App\\Models\\SAFieldDay'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <FocusVillage
          v-if="activity.activity_type == 'App\\Models\\SAFocusVillage'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <Harvest
          v-if="activity.activity_type == 'App\\Models\\SAHarvest'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
        <Other
          v-if="activity.activity_type == 'App\\Models\\SAOtherCommercial'"
          :myLocation="myLocation"
          :activity="activity"
          @submitMaster="submitMaster"
        />
      </div>
    </div>
  </AppLayout>
</template>
