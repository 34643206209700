import { defineStore } from 'pinia';

export const useVillageStore = defineStore({
    id: 'village',
    state() {
        return {
            database: null,
            villages: [],
            activeVillage: {},
        };
    },
    actions: {
        async init() {
            // await this.initDatabase();
            this.initVillages();
        },
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database 2.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };

                // db.onupgradeneeded = (e) => {
                //     if (e.oldVersion === 1) {
                //         e.target.result.deleteObjectStore("villages");
                //     }

                //     e.target.result.createObjectStore("villages", { keyPath: "id" });
                // };
            });
        },
        async initVillages() {
            await this.initDatabase();
            const villages = await new Promise((resolve, reject) => {
                this.database.transaction('villages')
                    .objectStore('villages')
                    .getAll()
                    .onsuccess = (e) => {
                        resolve(e.target.result);
                    };
            });

            this.updateVillages(villages);
        },
        async getVillage(dataId) {
            await this.initDatabase();
            let id = parseInt(dataId);
            if (!this.database) {
                throw new Error('Database not initialized');
            }

            const transaction = this.database.transaction('villages', 'readwrite');

            const villageStore = transaction.objectStore('villages');
            const villageRequest = villageStore.get(dataId);

            villageRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            villageRequest.onsuccess = (e) => {
                const findVillage = e.target.result;
                if (findVillage) {
                    this.updateActiveVillage(findVillage);
                }
            }
        },
        async addNewVillage(data) {
            await this.initDatabase();
            const transaction = this.database.transaction('villages', 'readwrite');

            const villageStore = transaction.objectStore('villages');
            const villageRequest = villageStore.get(data.value);

            villageRequest.onerror = () => {
                console.error('Error find the in the database.');
            };

            villageRequest.onsuccess = (e) => {
                const findVillage = e.target.result;
                if (findVillage) {
                    findVillage.updated = new Date().getTime();

                    const updateRequest = villageStore.put(findVillage);
    
                    updateRequest.onerror = () => {
                        console.error('Error storing the updated in the database.');
                    };
    
                    updateRequest.onsuccess = () => {
                        const villages = [...this.villages];
                        const villageIndex = villages.findIndex(n => n.id === findVillage.id);
                        villages[villageIndex] = findVillage;
    
                        this.updateVillages(villages);
                    };
                } else {
                    const now = new Date();
                    const newData = {
                        content: data,
                        created: now.getTime(),
                        updated: now.getTime(),
                        id: data.value
                    };
        
                    const villages = [newData, ...this.villages];
                    this.updateVillages(villages);
        
                    this.updateActiveVillage(newData);
    
                    transaction.objectStore('villages').add(newData);
                }
            }
        },

        updateDatabase(database) {
            this.database = database
        },
        updateVillages(villages) {
            this.villages = villages
        },
        updateActiveVillage(village) {
            this.activeVillage = village
        },
        getVillages() {
            return this.villages;
        }
    },
    getters: {},
});
