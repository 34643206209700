<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppLayout from "@/layouts/AppLayout.vue";
import Label from "@/components/Label";
import { notify } from "@kyvg/vue3-notification";
import { api } from "@/services/api";
import { utils } from "@/utilities";

import ClockInOutFS from "@/firebases/ClockInOutFS";

import { useContextStore } from "@/stores/context";
const contextStore = useContextStore();

const router = useRouter();

const miles = ref(0);
const form = reactive({
  work_location: "",
  name: "",
  latitude: "",
  longitude: "",

  current_latitude: "",
  current_longitude: "",
  remark: "",

  type: "clock-out",

  needUpdateClockOut: false,
  distance: 0,
  clockInMiles: 0,
  clockOutMiles: 0,

  hashing: utils.generateUUID(),
});

onMounted(async () => {
  // Call the function to get the current position
  utils.getCurrentPosition().then((location) => {
    if (location) {
      form.current_latitude = location.latitude;
      form.current_longitude = location.longitude;
      form.latitude = location.latitude;
      form.longitude = location.longitude;
    } else {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "Can't get the current position!",
      });
    }
  });

  let clockInOut = await ClockInOutFS.getTodayClockInOut();
  if (clockInOut.clockIn && clockInOut.formClockIn) {
    form.clockInMiles = clockInOut.formClockIn['miles'];
  }
});

const submit = async () => {
  if (contextStore.getIsOffline) {
    form.needUpdateClockOut = true;
    ClockInOutFS.addClockOutToday(form);

    router.push("/?isClockOut=1");
  } else {
    const clockInOut = await api.clockInOut({ formClockOut: form });
    if (clockInOut.status) {
      ClockInOutFS.addClockOutToday(clockInOut.data['clockOut']);

      router.push("/");
    } else {
      const error = clockInOut.data;
      for (const [key, value] of Object.entries(error)) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: "There was an error!",
          text: value + " [ " + key + " ]",
        });

        if (key == "clock-out") {
          ClockInOutFS.addClockOutToday({});

          router.push("/");
        }

        return;
      }
    }
  }
};

watch(
  () => [
    form.clockInMiles, form.clockOutMiles
  ],
  (n, o) => {
    let cal = parseInt(form.clockOutMiles) - parseInt(form.clockInMiles);
    if (isNaN(cal)) {
      miles.value = 0;
    } else {
      miles.value = cal < 0 ? 0 : cal;
    }
  }
);
</script>

<template>
  <AppLayout>
    <div class="relative overflow-x-auto my-6">
      <div class="text-lg font-bold my-3 text-left">Clock Out</div>
      <form class="space-y-4 text-left" action="#" @submit.prevent="submit">

        <div class="py-2">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <div>
              <Label
                  class="block mb-2 text-sm font-medium text-gray-900 required"
                  value="Clock In Miles"
                />
                <input
                  type="text"
                  v-model="form.clockInMiles"
                  @focus="utils.onFocusDefaultVal($event)"
                  class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
            </div>

            <div>
              <Label
                  class="block mb-2 text-sm font-medium text-gray-900 required"
                  value="Clock Out Miles"
                />
                <input
                  type="text"
                  v-model="form.clockOutMiles"
                  @focus="utils.onFocusDefaultVal($event)"
                  class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
            </div>

            <div>
              <Label
                  class="block mb-2 text-sm font-medium text-gray-900 required"
                  value="Miles"
                />
                <input
                  type="text"
                  :value="miles"
                  class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  readonly
                  disabled
                />
            </div>
          </div>
        </div>

        <div class="py-2">
          <Label
            class="block mb-2 text-sm font-medium text-gray-900"
            value="Remark"
          />
          <textarea
            class="mt-0 block w-full rounded-lg resize-y"
            rows="3"
            v-model="form.remark"
          />
        </div>

        <div class="flex justify-end">
          <button
            type="submit"
            class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
          >
            Clock Out
          </button>
        </div>
      </form>
    </div>
  </AppLayout>
</template>
