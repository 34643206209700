import { createRouter, createWebHistory } from 'vue-router'
import { authApi } from '@/services/authApi';
import { useContextStore } from '@/stores/context';

import Login from '@/components/Auth/Login.vue'

import Dashboard from '@/views/Dashboard.vue';
import Today from '@/views/Activity/Today.vue';
import CheckOut from '@/views/Activity/CheckOut.vue';
import ClockIn from '@/views/ClockIn.vue';
import ClockOut from '@/views/ClockOut.vue';
import { utils } from '@/utilities';

import ActivityFS from '@/firebases/ActivityFS';
import ClockInOutFS from '@/firebases/ClockInOutFS';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Today
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: (to, from, next)  => {
      const contextStore = useContextStore();
      if (contextStore.getIsOffline) {
        alert('Please log out when you are online!');

        return { path: '/' }
      }

      contextStore.updateAuth(null);
      contextStore.updateUser(null);

      window.$cookies.remove('auth');
      return { path: '/login' }
    },
  },
  {
    path: '/check-out/:activity_id',
    name: 'check-out',
    component: CheckOut
  },

  {
    path: '/clock-in',
    name: 'clock-in',
    component: ClockIn
  },
  {
    path: '/clock-out',
    name: 'clock-out',
    component: ClockOut
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  }
]

const router = createRouter({
  history: createWebHistory(),
  // mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
  routes
});

const isAuthenticated = async () => {
  const contextStore = useContextStore();
  const access_token = contextStore.getAccessToken;

  // console.log("access_token", access_token)

  // return without validate because is so fast can't read cookie and wait response form api
  // wait cookies destroy myself
  return window.$cookies.isKey('auth') && access_token;
}

const cronJob = async () => {
  if (
    window.$cookies.isKey('lastUpdate') && // has cookie
    utils.isDayPass( parseInt( window.$cookies.get('lastUpdate') ) ) === false // past last update more than 1 days
  ) {
    return; // do nothing
  }

  // Clear Previous activity noting change
  await ActivityFS.clearPrevious();
  await ClockInOutFS.clearPrevious();
  window.$cookies.set('lastUpdate', new Date().getTime());
}

router.beforeEach(async (to, from, next) => {
  const contextStore = useContextStore();
  const auth = contextStore.getAuth
  // console.log("AUTH", await isAuthenticated())
  // console.log(to.name !== 'login' && !(await isAuthenticated(access_token)), (await isAuthenticated(access_token)))

  await cronJob();
  
  if (to.name === 'login' && await isAuthenticated()) {
    // If go to login page and Authentication redirect to home
    next({ name: '' });
  } else if (to.name !== 'login' && !(await isAuthenticated())) {
    // If go to any page and Unauthenticated redirect to login page
    next({ name: 'login' });
  } else if (auth && new Date() > new Date(auth.expired_at)) {
    // If is expired, redirect to login page
    next({ name: 'logout' });
  } else {
    // otherwise
    next()
  }
});

export default router