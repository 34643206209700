import { useCollection, useDatabaseList } from "vuefire";
import {
    query,
    collection,
    setDoc,
    doc,
    addDoc,
    getDoc,
    getDocs,
    deleteDoc,
    onSnapshot,
    where,
    enableNetwork,
    disableNetwork,
    getDocFromCache,
    orderBy,
    serverTimestamp
} from "firebase/firestore";
import { api } from "@/services/api";
import { utils } from "@/utilities";
import { databaseFireStore } from "@/firebase";
import { useContextStore } from "@/stores/context";
import { useFileStore } from "@/stores/file";
import LogFS from "@/firebases/LogFS";

class ActivityFS {
    tableName() {
        const contextStore = useContextStore();
        const user = contextStore.getUser

        let db = 'activities';
        if (user && user?.id) {
            db = 'activities_' + user.id;
        }

        const prefix = utils.isDev() ? 'dev_' : 'prod_';
        return prefix + db;
    }

    async updateAllActivities() {
        return new Promise((resolve, reject) => {
            let promises = [];
            const q = query(collection(databaseFireStore, this.tableName()));

            onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                snapshot.docs.forEach((content) => {
                    const _data = content.data();
                    promises.push(this.temporaryToNewRecord(_data));

                    promises.push(this.temporarySalesCheckInToNewRecord(_data));

                    promises.push(this.temporarySalesCheckOutToNewRecord(_data));
                });


                Promise.all(promises)
                    .then((results) => {
                        // Once all Promises are resolved, log "complete" and resolve the main Promise with args
                        resolve(promises);
                    })
                    .catch((error) => {
                        reject(error); // Reject the main Promise if any of the Promises fail
                    });
            });
        });
    }

    async clearPrevious() {
        return new Promise((resolve, reject) => {
            let promises = [];
            const q = query(collection(databaseFireStore, this.tableName()));

            onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                snapshot.docs.forEach((content) => {
                    const _data = content.data();

                    if (utils.isDayPass(_data.schedule_date) && utils.isActivityHasChanged(_data) === false) {
                        console.log('Remove previous activity id: ' + _data.id);
                        this.delete(_data.id);
                    }
                });

                Promise.all(promises)
                    .then((results) => {
                        // Once all Promises are resolved, log "complete" and resolve the main Promise with args
                        resolve(promises);
                    })
                    .catch((error) => {
                        reject(error); // Reject the main Promise if any of the Promises fail
                    });
            });
        });
    }

    async getActivities(currentDate) {
        // stupid fix date format
        const startDate = currentDate + ' 00:00:00';
        const endDate = currentDate + ' 23:59:59';
        
        return new Promise((resolve, reject) => {
            let activities = [];
            const q = query(collection(databaseFireStore, this.tableName()), where("schedule_date", ">=", startDate), where("schedule_date", "<=", endDate));
            // const q = query(collection(databaseFireStore, this.tableName()));

            onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                // snapshot.docs.forEach((content) => {
                // console.log(content.data());
                // activities.push(content.data());
                // });
                snapshot.docChanges().forEach((content) => {
                    // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                    // console.log("Data came from " + source, content.doc.data());
                    activities.push(content.doc.data());
                });

                resolve(activities);
            });
        });
    }

    async getById(id) {
        const contextStore = useContextStore();
        return new Promise(async (resolve, reject) => {
            try {
                // Convert the ID to a string
                const idString = id.toString();

                const docRef = doc(databaseFireStore, this.tableName(), idString);

                if (contextStore.getIsOffline) {
                    var docSnap = await getDocFromCache(docRef);
                    // console.log("getDocFromCache", docSnap);
                } else {
                    var docSnap = await getDoc(docRef);
                    // console.log("getDoc", docSnap);
                }

                if (docSnap.exists()) {
                    // console.log("Document data:", { id: idString, ...docSnap.data() });
                    resolve({ id: idString, ...docSnap.data() });
                } else {
                    resolve(null);
                }
            } catch (error) {
                LogFS.createLog(error);
                reject(error);
            }
        });
    }

    async temporaryToNewRecord(activity) {
        const contextStore = useContextStore();
        const fileStore = useFileStore();

        if (contextStore.getIsOffline === false) {
            // expect id form firebase
            if (activity?.id && isNaN(activity.id)) {

                var deleteRecord = [];
                if (activity?.tempFiles && activity?.sales_checkout) {
                    activity.files = [];
                    for (let file of activity.tempFiles) {
                        let fileData = await fileStore.getFile(file);
                        //   console.log(file, fileData.data, activity);
                        activity.files.push(fileData.data);
                        deleteRecord.push(fileData.id);
                    }

                    activity._method = 'put';
                    activity.sales_checkout._method = 'put';
                }

                const res = await api.addUnPlanActivity(activity);
                if (res.status) {
                    if (deleteRecord) {
                        for (let fileId of deleteRecord) {
                            await fileStore.deleteFile(fileId);
                        }
                    }
                }

                return res;
            }
        }

        return false
    }

    async temporarySalesCheckInToNewRecord(activity) {
        const contextStore = useContextStore();
        if (contextStore.getIsOffline === false) {
            // expect -1
            if (activity?.id && !isNaN(activity.id) && activity.sales_checkin && activity.sales_checkin?.id == -1) {
                return await api.checkInActivity(activity.sales_checkin);
            }
        }

        return false;
    }

    async temporarySalesCheckOutToNewRecord(activity) {
        const contextStore = useContextStore();
        const fileStore = useFileStore();

        if (contextStore.getIsOffline === false) {
            // expect -1
            if (activity?.id && !isNaN(activity.id) && activity.sales_checkout && activity.sales_checkout?.id == -1) {

                var deleteRecord = [];
                if (activity?.tempFiles) {
                    activity.sales_checkout.files = [];
                    for (let file of activity.tempFiles) {
                        let fileData = await fileStore.getFile(file);
                        activity.sales_checkout.files.push(fileData.data);
                        deleteRecord.push(fileData.id);
                    }
                }

                const res = await api.checkOutActivity(activity.sales_checkout);
                if (res.status) {
                    if (deleteRecord) {
                        for (let fileId of deleteRecord) {
                            await fileStore.deleteFile(fileId);
                        }
                    }
                }

                return res;
            }
        }

        return false;
    }

    async createOrUpdateActivity(data) {
        try {
            var tempId = utils.generateUUID();
            if (data?.id) {
                tempId = data.id;
            }

            tempId = tempId.toString();
            data.id = tempId;
            return await setDoc(doc(databaseFireStore, this.tableName(), tempId), data, { merge: true });
        } catch (error) {
            LogFS.createLog(error);
            console.error("Error adding document: ", error);
        }
    }

    // Update an existing record
    async update(id, data) {
        const idString = id.toString();

        return await setDoc(doc(databaseFireStore, this.tableName(), idString), data);
    }

    // Delete a specific record
    async delete(id) {
        const idString = id.toString();
        console.log("Deleting " + idString);
        return await deleteDoc(doc(databaseFireStore, this.tableName(), idString));
    }
}

export default new ActivityFS();