import { useCollection, useDatabaseList } from "vuefire";
import {
    query,
    collection,
    setDoc,
    doc,
    addDoc,
    getDoc,
    getDocs,
    deleteDoc,
    onSnapshot,
    where,
    enableNetwork,
    disableNetwork,
    getDocFromCache
} from "firebase/firestore";
import { api } from "@/services/api";
import { utils } from "@/utilities";
import { databaseFireStore } from "@/firebase";
import { useContextStore } from "@/stores/context";
import { useFileStore } from "@/stores/file";

class LogFS {
    tableName() {
        let db = 'logs';
        
        const prefix = utils.isDev() ? 'dev_' : 'prod_';
        return prefix + db;
    }

    async createLog(data) {
        try {
            const contextStore = useContextStore();
            const user = contextStore.getUser;

            const object = {
                user: user?.id ?? 0,
                dateTime: new Date(),
                content: utils.jsonFriendlyErrorReplacer(data)
            };
            console.log("error", object);
            
            // return await addDoc(collection(databaseFireStore, this.tableName()), object);

            // Get current date in YYYY-MM-DD format
            const currentDate = new Date().toISOString().split('T')[0];

            // Reference to the nested collection with the current date
            const dateCollectionRef = collection(databaseFireStore, this.tableName(), currentDate, 'entries');

            // Add a new document with a random ID and log data
            const newDocRef = doc(dateCollectionRef);
            await setDoc(newDocRef, object);
        } catch (e) {
            console.error("Error write log: ", e);
        }
    }
}

export default new LogFS();