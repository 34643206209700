import { defineStore } from 'pinia';

export const useFileStore = defineStore({
    id: 'file',
    state() {
        return {
            database: null,
        };
    },
    actions: {
        async initDatabase() {
            return new Promise((resolve, reject) => {
                let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

                db.onerror = () => {
                    reject('Error opening the database.');
                };

                db.onsuccess = (e) => {
                    this.updateDatabase(e.target.result);
                    resolve('OK');
                };
            });
        },
        async saveFile(file, name) {
            return new Promise((resolve, reject) => {
                const transaction = this.database.transaction('files', 'readwrite');

                var objectStore = transaction.objectStore('files');
                // var request = objectStore.add({ id: file.name, name: file.name, type: file.type, data: file });
                var request = objectStore.add({ id: name, name: file.name, type: file.type, data: file });

                request.onerror = function (event) {
                    console.error('Error adding file to IndexedDB');
                    reject(event);
                };

                request.onsuccess = function (event) {
                    console.log('File added to IndexedDB');
                    resolve(event);
                };
            });
        },
        async getFile(id) {
            return new Promise((resolve, reject) => {
                const transaction = this.database.transaction('files', 'readwrite');
                var objectStore = transaction.objectStore('files');
                var request = objectStore.get(id);

                request.onsuccess = function (event) {
                    resolve(request.result);
                };

                request.onerror = function (event) {
                    reject('Error retrieving file from IndexedDB');
                };
            });
        },
        async deleteFile(id) {
            return new Promise((resolve, reject) => {
                const transaction = this.database.transaction('files', 'readwrite');
                var objectStore = transaction.objectStore('files');
                var request = objectStore.delete(id);

                request.onsuccess = function (event) {
                    console.log('File deleted from IndexedDB');
                    resolve(event);
                };

                request.onerror = function (event) {
                    reject('Error deleting file from IndexedDB');
                };
            });
        },

        updateDatabase(database) {
            this.database = database
        },
    },
    getters: {

    },
    // mutations are not necessary in Pinia
});
