import { useCollection, useDatabaseList } from "vuefire";
import {
    query,
    collection,
    setDoc,
    doc,
    addDoc,
    getDoc,
    getDocs,
    deleteDoc,
    onSnapshot,
    where,
    enableNetwork,
    disableNetwork,
    updateDoc,
    getDocFromCache
} from "firebase/firestore";
import { api } from "@/services/api";
import { utils } from "@/utilities";
import { databaseFireStore } from "@/firebase";
import { useContextStore } from "@/stores/context";
import LogFS from "@/firebases/LogFS";

class ClockInOutFS {
    tableName() {
        const contextStore = useContextStore();
        const user = contextStore.getUser

        let db = 'clockInOut';
        if (user && user?.id) {
            db = 'clockInOut_' + user.id;
        }

        const prefix = utils.isDev() ? 'dev_' : 'prod_';
        return prefix + db;
    }

    async addClockInToday(form) {
        const data = {
            id: utils.getCurrentDate(),
            clockIn: true,
            formClockIn: form
        };

        return await this.createOrUpdate(data);
    }

    async addClockOutToday(form) {
        const todayClockIn = await this.getTodayClockInOut();

        const data = {
            id: utils.getCurrentDate(),
            clockIn: true,
            clockOut: true,
            formClockOut: form,
            formClockIn: todayClockIn?.formClockIn ?? {},
        };

        return await this.createOrUpdate(data);
    }

    async getTodayClockInOut() {
        return new Promise(async (resolve, reject) => {
            const contextStore = useContextStore();

            try {
                // Convert the ID to a string
                const idString = utils.getCurrentDate();

                const docRef = doc(databaseFireStore, this.tableName(), idString);
                // const docSnap = await getDoc(docRef);
                if (contextStore.getIsOffline) {
                    var docSnap = await getDocFromCache(docRef);
                    // console.log("getDocFromCache", docSnap);
                } else {
                    var docSnap = await getDoc(docRef);
                    // console.log("getDoc", docSnap);
                }

                if (docSnap.exists()) {
                    // console.log("Document data:", { id: idString, ...docSnap });
                    resolve({ id: idString, ...docSnap.data() });
                } else {
                    resolve(null);
                }
            } catch (error) {
                LogFS.createLog(error);
                reject(error);
            }
        });
    }

    async updateAllClockInOut() {
        return new Promise((resolve, reject) => {
            let promises = [];
            const q = query(collection(databaseFireStore, this.tableName()));

            onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                snapshot.docs.forEach((content) => {
                    promises.push(this.temporaryClockInOutToNewRecord(content.data()));
                    // promises.push(this.temporaryClockInToNewRecord(content.data()));

                    // promises.push(this.temporaryClockOutToNewRecord(content.data()));
                });

                // Wait for all Promises to resolve using Promise.all()
                Promise.all(promises)
                    .then((results) => {
                        // Once all Promises are resolved, log "complete" and resolve the main Promise with args
                        resolve(promises);
                    })
                    .catch((error) => {
                        reject(error); // Reject the main Promise if any of the Promises fail
                    });
            });
        });
    }

    async temporaryClockInOutToNewRecord(clockInOut) {
        const contextStore = useContextStore();

        if (contextStore.getIsOffline === false) {
            let form = {};

            let callAPI = false;
            if (clockInOut?.id && clockInOut?.formClockIn && clockInOut.formClockIn?.needUpdateClockIn && clockInOut.formClockIn.needUpdateClockIn) {
                form['formClockIn'] = clockInOut.formClockIn;
                form['formClockIn']['type'] = 'clock-in';

                callAPI = true;
            }

            if (clockInOut?.id && clockInOut?.formClockOut && clockInOut.formClockOut?.needUpdateClockOut && clockInOut.formClockOut.needUpdateClockOut) {
                form['formClockOut'] = clockInOut.formClockOut;
                form['formClockOut']['type'] = 'clock-out';

                callAPI = true;
            }

            if (callAPI) {
                const res = await api.clockInOut(form);
                if (res.status) {
                    this.update(clockInOut.id, {
                        id: clockInOut.id,
                        clockIn: res.data['clockIn'] ? true : false,
                        clockOut: res.data['clockOut'] ? true : false,
                    });
                } else {
                    const error = res.data;
    
                    if (Object.keys(error).includes('clock-in') || Object.keys(error).includes('clock-out')) {
                        this.update(clockInOut.id, {
                            id: clockInOut.id,
                            clockIn: clockInOut?.clockIn ? true : false,
                            clockOut: clockInOut?.clockOut ? true : false,
                        });
                    }
                }
    
                return res;
            }
        }

        return false;
    }

    async temporaryClockInToNewRecord(clockInOut) {
        const contextStore = useContextStore();

        if (contextStore.getIsOffline === false) {
            if (clockInOut?.id && clockInOut?.formClockIn && clockInOut.formClockIn?.needUpdateClockIn && clockInOut.formClockIn.needUpdateClockIn) {
                const res = await api.clockInOut("clock-in", clockInOut.formClockIn);

                if (res.status) {
                    this.update(clockInOut.id, {
                        id: clockInOut.id,
                        clockIn: clockInOut?.clockIn ? true : false,
                        clockOut: clockInOut?.clockOut ? true : false,
                        formClockIn: res.data['clockIn']
                    });
                } else {
                    const error = res.data;

                    if (Object.keys(error).includes('clock-in')) {
                        this.update(clockInOut.id, {
                            id: clockInOut.id,
                            clockIn: clockInOut?.clockIn ? true : false,
                            clockOut: clockInOut?.clockOut ? true : false,
                        });
                    }
                }

                return res;
            }
        }

        return false;
    }

    async temporaryClockOutToNewRecord(clockInOut) {
        const contextStore = useContextStore();

        if (contextStore.getIsOffline === false) {
            if (clockInOut?.id && clockInOut?.formClockOut && clockInOut.formClockOut?.needUpdateClockOut && clockInOut.formClockOut.needUpdateClockOut) {
                const res = await api.clockInOut("clock-out", clockInOut.formClockOut);

                if (res.status) {
                    this.update(clockInOut.id, {
                        id: clockInOut.id,
                        clockIn: clockInOut?.clockIn ? true : false,
                        clockOut: clockInOut?.clockOut ? true : false,
                        formClockOut: res.data['clockOut']
                    });
                } else {
                    const error = res.data;

                    if (Object.keys(error).includes('clock-out')) {
                        this.update(clockInOut.id, {
                            id: clockInOut.id,
                            clockIn: clockInOut?.clockIn ? true : false,
                            clockOut: clockInOut?.clockOut ? true : false,
                        });
                    }
                }

                return res;
            }
        }

        return false;
    }

    async clearPrevious() {
        return new Promise((resolve, reject) => {
            let promises = [];
            const q = query(collection(databaseFireStore, this.tableName()));

            onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                snapshot.docs.forEach((content) => {
                    const _data = content.data();

                    if (
                        utils.isDayPass(_data.id) &&
                        !(_data.hasOwnProperty('needUpdateClockIn')) &&
                        !(_data.hasOwnProperty('needUpdateClockOut'))
                    ) {
                        console.log('Remove previous clock in out date: ', _data.id);
                        promises.push(this.delete(_data.id));
                    }
                });

                Promise.all(promises)
                    .then((results) => {
                        // Once all Promises are resolved, log "complete" and resolve the main Promise with args
                        resolve(promises);
                    })
                    .catch((error) => {
                        reject(error); // Reject the main Promise if any of the Promises fail
                    });
            });
        });
    }

    async createOrUpdate(data) {
        try {
            var tempId = data.id;
            tempId = tempId.toString();
            data.id = tempId;

            return await setDoc(doc(databaseFireStore, this.tableName(), tempId), data);
        } catch (error) {
            LogFS.createLog(error);
            console.error("Error adding document: ", error);
        }
    }

    // Update an existing record
    async update(id, data) {
        const idString = id.toString();

        return await setDoc(doc(databaseFireStore, this.tableName(), idString), data);
    }

    // Delete a specific record
    async delete(id) {
        const idString = id.toString();

        return await deleteDoc(doc(databaseFireStore, this.tableName(), idString));
    }
}

export default new ClockInOutFS();