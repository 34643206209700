<script setup>
import {
  ref,
  reactive,
  onMounted
} from "vue";
import Label from "@/components/Label";
import { utils } from "@/utilities.js";

const selectForms = ref(["Farmer", "Village", "Dealer", "Sub-dealer"]);

const props = defineProps(["myLocation", "activity"]);
const emits = defineEmits(["submitMaster"]);

const form = reactive({
  activity_type: "Others",
  date: utils.getCurrentDate(),
  budget_spent: props.activity?.budget ? parseFloat(props.activity.budget) : 0,
  select_form: "Farmer",
  feedback: "",
  description: "",
  files: "",

  check_out: true,
  sale_schedule: props.activity,

  latitude: props.myLocation.latitude,
  longitude: props.myLocation.longitude,
  current_latitude: props.myLocation.current_latitude,
  current_longitude: props.myLocation.current_longitude,
  distance: utils.getDistanceFromActivity(props.activity),

  isLaterActive: true,
});

onMounted(async () => {});

const submit = async () => {
  emits('submitMaster', form);
  return;
};
</script>
<template>
  <form class="space-y-4 text-left" action="#" @submit.prevent="submit">
    <div class="grid grid-cols-6 gap-6">
      <div class="col-span-6 sm:col-span-2">
        <Label value="Distance (Km)" />
        <input
          class="mt-1 block w-full rounded-lg resize-y"
          type="text"
          v-model="form.distance"
        />
      </div>

      <div class="col-span-6 sm:col-span-2">
        <Label for="date" value="Date" />
        <input
          id="date"
          type="date"
          :max="utils.getCurrentDate()"
          class="mt-1 block w-full rounded-lg"
          v-model="form.date"
          disabled
        />
      </div>
      <div class="col-span-6 sm:col-span-2">
        <Label for="activity_type" value="Activity Type" />
        <input
          id="activity_type"
          type="text"
          v-model="form.activity_type"
          class="mt-1 block w-full rounded-lg"
          disabled
        />
      </div>
      <div class="col-span-6 sm:col-span-6">
        <Label for="description" value="Description" class="required" />
        <textarea
          name=""
          id="description"
          cols="30"
          rows="5"
          class="mt-1 w-full rounded-lg"
          v-model="form.description"
          required
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="feedback" value="Feedback" />
        <input
          id="feedback"
          type="text"
          class="mt-1 block w-full rounded-lg"
          v-model="form.feedback"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="budget_spent" value="Budget Spend" class="required" />
        <input
          id="budget_spent"
          type="number"
          class="mt-1 block w-full rounded-lg"
          v-model="form.budget_spent"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @focus="utils.onFocusDefaultVal($event)"
          maxlength="7"
          min="0"
          required
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="start_date" value="Upload Media" />
        <input
          id="files"
          type="file"
          class="mt-1 block w-full rounded-lg shadow-sm"
          @input="form.files = Array.from($event.target.files)"
          multiple
        />
        <p class="text-sm text-gray-500 mt-2">max size per file: 10mb</p>
        <!-- <template v-for="(f, index) in form.files" :key="f.file">
        <input-error
          :message="form.errors?.[`files.${index}`]"
          class="mt-2"
          v-if="form.errors?.[`files.${index}`]"
        />
      </template> -->
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="select_form" value="Select Form" class="required" />
        <select
          v-model="form.select_form"
          id=""
          class="mt-1 block w-full rounded-lg"
          required
        >
          <option
            :value="selectForm"
            v-for="selectForm in selectForms"
            :key="selectForm"
          >
            {{ selectForm }}
          </option>
        </select>
      </div>

      <div class="flex justify-end col-span-6">
        <button
          type="submit"
          class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
        >
          Check Out
        </button>
      </div>
    </div>
  </form>
</template>
