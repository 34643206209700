import { defineStore } from 'pinia';
// This is create for break duplicate addUnPlanning when application going back to internet after offline and have data
export const useActivityAsyncStore = defineStore({
    id: 'activity-async',
    state() {
        return {
            syncKeys: [],
        };
    },
    actions: {
        addSyncAsync(key) {
            return this.syncKeys.push(key);
        },
    },
    getters: {
        getSyncAsync(state) {
            return state.syncKeys;
        }
    },
});
